<template>
  <default-layout no-auth>
    <div class="back-link">
      <button v-button:to-login-form @click="$move('/')"/>
    </div>
    <section>
      <h2 class="section-title"><span class="material-icons">password</span>パスワードをお忘れの方</h2>
      <form>
        <template v-if="!sent">
          <p class="pt-2 pl-2 pb-1">
            メールアドレスを入力してください。<br>
            パスワード再設定の案内メールを送信します。
          </p>
          <div class="form-inner email">
            <span class="font-weight-bold">メールアドレス：</span>
            <input-text v-model="email" :error="$error('email')"/>
          </div>
        </template>
        <template v-else>
          <p class="pt-2 pl-2 pb-1">
            {{email}} 宛てにメールを送信しました。<br>
            メール文中のURLからパスワードを再設定してください。
          </p>
        </template>
        <div class="p-2">
          <button v-if="!sent" @click="send" v-button:primary>送信</button>
          <button v-else @click="back" v-button:primary>戻る</button>
        </div>
      </form>
    </section>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";

export default {
  data() {
    return {
      email: "",
      sent: false,
      error: {}
    }
  },
  methods: {
    send() {
      Service.sendResetUrl(this.email)
          .then(() => this.sent = true)
          .catch(e => this.error = e);
    },
    back() {
      this.email = '';
      this.sent = false;
    }
  }
}
</script>
